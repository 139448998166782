// @flow

import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import { updateDisplayParameter } from '_client/redux/actions'
import { connect as withRedux } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getModesData } from 'utils/modes'
import { useMutation } from '@apollo/client'
import { updateAppSettingsUserQuery } from 'quote/quote-queries'
import { SEARCH_FIELDS } from 'auth/constants'
import Loader from '@material-ui/core/CircularProgress'
import compose from 'recompose/compose'
import Button from '@autodisol/ads-js/components/CustomButton'

const MAX_SHOW_FIELDS = {
  standard: 8,
  resval: 9,
}

const styles = () => ({
  show__fields: {
    display: 'flex',
    alignItems: 'center',
    '& > p': {
      marginRight: 10,
    },
  },
})

const DisplayParameter = ({
  classes,
  dispatch,
  selectedFieldsPreset,
  mode,
  appSettingsDisplayParameter,
}: {
  classes: Object,
  dispatch: Function,
  selectedFieldsPreset: Object,
  mode: string,
  appSettingsDisplayParameter?: Object,
}) => {
  const { t } = useTranslation()

  const [updateAppSettingsUser, { loading: isLoadingUpdateAppSettingsUser }] = useMutation(
    updateAppSettingsUserQuery,
    {
      onCompleted: data => {
        dispatch(updateDisplayParameter(data.update_app_settings_user.provider.displayParameter))
      },
    },
  )

  const nbSelectedFields = Object.values(selectedFieldsPreset || {}).filter(element =>
    Boolean(element),
  ).length

  const nbMaxShowFields = MAX_SHOW_FIELDS[mode]

  const handleChangeSelectedField =
    ({ fieldName }) =>
    (_, value) => {
      const selectedFieldsUpdated = { ...selectedFieldsPreset, [fieldName]: value }

      const displayParameter = appSettingsDisplayParameter
        ? { ...appSettingsDisplayParameter, [mode]: selectedFieldsUpdated }
        : { ...SEARCH_FIELDS, [mode]: selectedFieldsUpdated }

      updateAppSettingsUser({ variables: { settings: { displayParameter } } })
    }

  const handleResetDisplayParameter = () => {
    const displayParameter = appSettingsDisplayParameter
      ? { ...appSettingsDisplayParameter }
      : { ...SEARCH_FIELDS }

    const newDisplayParameter = { ...displayParameter, [mode]: SEARCH_FIELDS[mode] }

    updateAppSettingsUser({
      variables: {
        settings: {
          displayParameter: newDisplayParameter,
        },
      },
    })
  }

  return (
    <Grid container>
      <Grid item className={classes.show__fields}>
        <Typography variant="body1">
          {t('search.showFields', { nbFields: nbMaxShowFields })}
        </Typography>
        {isLoadingUpdateAppSettingsUser && <Loader size={20} />}
      </Grid>
      <Grid container xs={12} style={{ marginTop: 15, marginBottom: 30 }}>
        {Object.keys(selectedFieldsPreset).map(fieldName => {
          const isDisabledCheckbox =
            (nbSelectedFields === nbMaxShowFields && !selectedFieldsPreset[fieldName]) ||
            isLoadingUpdateAppSettingsUser

          return (
            <Grid item xs={12} md={3} key={fieldName}>
              <FormGroup>
                <FormControlLabel
                  disabled={isDisabledCheckbox}
                  control={<CheckboxStyle checked={selectedFieldsPreset[fieldName]} />}
                  onChange={handleChangeSelectedField({ fieldName })}
                  label={t(`search.${fieldName}`)}
                />
              </FormGroup>
            </Grid>
          )
        })}
      </Grid>

      <Button
        colorType="primary"
        height="short"
        variant="contained"
        disabled={isLoadingUpdateAppSettingsUser}
        onClick={handleResetDisplayParameter}
      >
        {t('quote.resetDisplayParameters')}
      </Button>
    </Grid>
  )
}

const CheckboxStyle = withStyles(theme => ({
  root: {
    padding: '5px 5px 5px 15px',
    color: theme.palette.secondary.light,
    '&$checked': {
      color: theme.palette.secondary.dark,
    },
  },
  checked: {},
}))(Checkbox)

export default (compose(
  withRedux(({ user, router }) => {
    const { currentMode } = getModesData(user, router.location.pathname)

    return {
      selectedFieldsPreset: user.data?.displayParameter?.[currentMode] ?? {},
      mode: currentMode,
      appSettingsDisplayParameter: user.data?.appSettings?.provider?.displayParameter,
    }
  }),
  withStyles(styles),
)(DisplayParameter): any)
