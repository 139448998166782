import React from 'react'
import { IDENTIFICATION_VEHICLE_TYPE } from 'utils/constants'
import SchemaDashboardFromBack from '../../../public/img/SchemaDashboardFromBack.svg'
import SchemaDashboard from '../../../public/img/SchemaDashboard.svg'
import SchemaVp3Av from '../../../public/img/SchemaVp3Av.svg'
import SchemaVp3Ar from '../../../public/img/SchemaVp3Ar.svg'
import SchemaVp5Av from '../../../public/img/SchemaVp5Av.svg'
import SchemaVp5Ar from '../../../public/img/SchemaVp5Ar.svg'
import SchemaVuAr from '../../../public/img/SchemaVuAr.svg'
import SchemaVuAv from '../../../public/img/SchemaVuAv.svg'
import SchemaMotoAvLeft from '../../../public/img/SchemaMotoAvLeft.svg'
import SchemaMotoArLeft from '../../../public/img/SchemaMotoArLeft.svg'
import SchemaMotoAvRight from '../../../public/img/SchemaMotoAvRight.svg'
import SchemaMotoArRight from '../../../public/img/SchemaMotoArRight.svg'
import SchemaMotoDashboard from '../../../public/img/SchemaMotoDashboard.svg'

const SCHEMA_5_DESCRIPTION = [
  {
    id: 'schemaAv',
    tags: 'picture_view_three_quarter_front_left',
    svg: <SchemaVp5Av />,
    subtitle: false,
  },
  {
    id: 'schemaAr',
    tags: 'picture_view_three_quarter_back_right',
    svg: <SchemaVp5Ar />,
    subtitle: false,
  },
  {
    id: 'schemaDashboardFromBack',
    tags: 'picture_view_dashboard_from_back',
    svg: <SchemaDashboard />,
    subtitle: true,
  },
  {
    id: 'schemaDashboard',
    tags: 'picture_view_running_dashboard',
    svg: <SchemaDashboardFromBack />,
    subtitle: true,
  },
]

const SCHEMA_3_DESCRIPTION = [
  {
    id: 'schemaAv',
    tags: 'picture_view_three_quarter_front_left',
    svg: <SchemaVp3Av />,
    subtitle: false,
  },
  {
    id: 'schemaAr',
    tags: 'picture_view_three_quarter_back_right',
    svg: <SchemaVp3Ar />,
    subtitle: false,
  },
  {
    id: 'schemaDashboardFromBack',
    tags: 'picture_view_dashboard_from_back',
    svg: <SchemaDashboard />,
    subtitle: true,
  },
  {
    id: 'schemaDashboard',
    tags: 'picture_view_running_dashboard',
    svg: <SchemaDashboardFromBack />,
    subtitle: true,
  },
]

const SCHEMA_VU_DESCRIPTION = [
  {
    id: 'schemaAv',
    tags: 'picture_view_three_quarter_front_left',
    svg: <SchemaVuAv />,
    subtitle: false,
  },
  {
    id: 'schemaAr',
    tags: 'picture_view_three_quarter_back_right',
    svg: <SchemaVuAr />,
    subtitle: false,
  },
  {
    id: 'schemaDashboardFromBack',
    tags: 'picture_view_dashboard_from_back',
    svg: <SchemaDashboard />,
    subtitle: true,
  },
  {
    id: 'schemaDashboard',
    tags: 'picture_view_running_dashboard',
    svg: <SchemaDashboardFromBack />,
    subtitle: true,
  },
]

const SCHEMA_BIKE_DESCRIPTION = [
  {
    id: 'bike.schemaAvRight',
    tags: 'picture_view_three_quarter_front_right',
    svg: <SchemaMotoAvRight />,
    subtitle: false,
  },
  {
    id: 'bike.schemaArRight',
    tags: 'picture_view_three_quarter_back_right',
    svg: <SchemaMotoArRight />,
    subtitle: false,
  },
  {
    id: 'bike.schemaArLeft',
    tags: 'picture_view_three_quarter_back_left',
    svg: <SchemaMotoArLeft />,
    subtitle: false,
  },
  {
    id: 'bike.schemaAvLeft',
    tags: 'picture_view_three_quarter_front_left',
    svg: <SchemaMotoAvLeft />,
    subtitle: false,
  },
  {
    id: 'bike.schemaDashboard',
    tags: 'picture_view_running_dashboard',
    svg: <SchemaMotoDashboard />,
    subtitle: false,
  },
]

const findSchema = (vehicleType, vehicleDoors) => {
  if (vehicleType === 'VU') return SCHEMA_VU_DESCRIPTION

  if (vehicleType === IDENTIFICATION_VEHICLE_TYPE.MOTO) return SCHEMA_BIKE_DESCRIPTION

  if (vehicleDoors && vehicleDoors < 4) SCHEMA_3_DESCRIPTION

  return SCHEMA_5_DESCRIPTION
}

export const getNameSchema = (vehicleType, vehicleDoors) => {
  if (vehicleType === 'VU') return vehicleType

  if (vehicleType === IDENTIFICATION_VEHICLE_TYPE.MOTO) return vehicleType

  return vehicleDoors && vehicleDoors < 4 ? 'VP3' : 'VP5'
}

export default findSchema
