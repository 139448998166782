// @flow

/* eslint-disable no-alert */

import React, { useEffect } from 'react'
import { connect as withRedux } from 'react-redux'
import compose from 'recompose/compose'
import withProps from 'recompose/withProps'
import Page from 'sharyn/components/Page'
import withFields from 'sharyn/hocs/with-fields'
import { elementIdentify } from '_client/redux/actions'
import QuoteSteps from 'quote/cmp/QuoteSteps'

type NewQuotePageProps = {
  identifiedVehicle?: Object,
  defaultQuoterGroupId?: string,
  currentDraftQuoteId?: string,
  routerHistory?: Object,
  identifyCountry?: string,
  dispatch: Function,
}

const NewQuotePageJSX = ({
  identifiedVehicle,
  defaultQuoterGroupId,
  currentDraftQuoteId,
  routerHistory,
  identifyCountry,
  dispatch,
}: NewQuotePageProps) => {
  useEffect(() => {
    dispatch(elementIdentify({}))
  }, [dispatch])

  return (
    <Page noPaper>
      <QuoteSteps
        {...{
          identifiedVehicle,
          defaultQuoterGroupId,
          currentDraftQuoteId,
          routerHistory,
          identifyCountry,
          identificationMode: identifiedVehicle ? 'auto' : 'manual',
        }}
      />
    </Page>
  )
}

const NewQuotePage: any = compose(
  withRedux(({ user, pageData }) => ({
    makesCatalog: pageData.get_makes_data,
    defaultQuoterGroupId: pageData.get_default_quoter_group_id,
    currentDraftQuoteId: user.data.currentDraftQuoteId,
  })),
  withFields(),
  withProps(props => ({
    identifiedVehicle: props.routerHistory.location.state?.identifiedVehicle,
    identifyCountry: props.routerHistory.location.state?.identifyCountry,
  })),
)(NewQuotePageJSX)

export default NewQuotePage
