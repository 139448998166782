// @flow
import { gql } from '@apollo/client'

export const listQuotesQuery = '{ list_quotes }'

export const listPricingsQuery =
  'query ($quoteIds: [String]) { list_pricing(filters: { quoteIn: $quoteIds }) }'

export const addQuotationQuery =
  'mutation ($quoteId: String!, $quoterGroupId: String) { add_quotation(quoteId: $quoteId, quoterGroupId: $quoterGroupId) }'

export const addQuotationMutation: any = gql`
  mutation ($quoteId: String!, $quoterGroupId: String) {
    add_quotation(quoteId: $quoteId, quoterGroupId: $quoterGroupId) {
      quote
      id
      quoter
      createdAt {
        date
        timezone_type
        timezone
      }
      updatedAt {
        date
        timezone_type
        timezone
      }
    }
  }
`

export const cancelQuoteMutation: any = gql`
  mutation ($id: String!) {
    cancel_quote(id: $id)
  }
`

export const createQuoteMutation: any = gql`
  mutation ($quoteInput: QuoteInput!) {
    create_quote(quoteInput: $quoteInput) {
      id
      status
      category
      ... on StandardQuote {
        currentKm
      }
      ... on ResValQuote {
        reference
        contractDuration
        contractDateStart {
          date
          timezone_type
          timezone
        }
        contractKmStart
        contractKm
      }
      archived
      identificationSource
      identificationCountry
      externalId
      externalSource
      enquirer
      enquirerGroup
      documents
      auctionGroup
      quotations
      prices {
        type
        amount
        createdAt {
          date
          timezone_type
          timezone
        }
      }
      quoteSites
      plate
      plateSearch
      vin
      make
      family
      subFamily
      model
      startModelPhase {
        date
        timezone_type
        timezone
      }
      endModelPhase {
        date
        timezone_type
        timezone
      }
      version
      startVersionPhase {
        date
        timezone_type
        timezone
      }
      endVersionPhase {
        date
        timezone_type
        timezone
      }
      trimLevel
      hpPower
      kwPower
      taxPower
      displacement
      gears
      gearbox
      mineType
      co2
      euroStandard
      color
      seats
      doors
      type
      body
      regBody
      regType
      productionCountry
      energy
      deliveryKm
      deliveryDate {
        date
        timezone_type
        timezone
      }
      releaseDate {
        date
        timezone_type
        timezone
      }
      firstRegDate {
        date
        timezone_type
        timezone
      }
      lastRegDate {
        date
        timezone_type
        timezone
      }
      tags
      firstSellPrice
      catalogId
      catalogSource
      catalogCountry
      expired
      assignedAt {
        date
        timezone_type
        timezone
      }
      damages
      tracking {
        id
        status
        tags
        comment
        soldDate {
          date
          timezone_type
          timezone
        }
        createdAt {
          date
          timezone_type
          timezone
        }
        updatedAt {
          date
          timezone_type
          timezone
        }
        createdAt {
          date
          timezone_type
          timezone
        }
        updatedAt {
          date
          timezone_type
          timezone
        }
      }
      quotations
      tracking {
        id
        status
        tags
        comment
        soldDate {
          date
          timezone_type
          timezone
        }
        createdAt {
          date
          timezone_type
          timezone
        }
        updatedAt {
          date
          timezone_type
          timezone
        }
      }
      damages
    }
  }
`

export const searchQuoteQuery: any = gql`
  query ($filters: QuoteSearchFilterInput, $page: Int, $nbElementByPage: Int) {
    search_quote(filters: $filters, page: $page, nbElementByPage: $nbElementByPage) {
      results {
        id
        status
        category
        plate
        vin
        make
        model
        version
        deliveryKm
        ... on StandardQuoteDocument {
          currentKm
        }
        ... on StandardBikeQuoteDocument {
          currentKm
        }
        ... on ResValQuoteDocument {
          reference
          contractDuration
          contractDateStart {
            date
            timezone_type
            timezone
          }
          updatedContractDateStart {
            date
            timezone_type
            timezone
          }
          contractKmStart
          contractKm
          pendingUpdate {
            id
            contractDuration
            contractKm
            contractKmStart
            expired
          }
        }
        releaseDate {
          date
          timezone_type
          timezone
        }
        deliveryDate {
          date
          timezone_type
          timezone
        }
        createdAt {
          date
          timezone_type
          timezone
        }
        updatedAt {
          date
          timezone_type
          timezone
        }
        enquirerGroup {
          name
          root {
            name
          }
        }
        enquirer {
          id
          fullname
        }
        quotations {
          quoterGroup {
            name
          }
          quoter {
            id
            fullname
          }
          publishedAt {
            date
            timezone_type
            timezone
          }
        }
        prices {
          type
          amount
          createdAt {
            date
            timezone_type
            timezone
          }
        }
        contact {
          fullname
        }
      }
      nbResults
      currentPage
      nbElementByPage
    }
  }
`

export const getQuoteAndCountQuery: any = gql`
  query getQuoteAndCountQuery($id: String!) {
    get_indexed_quote(quoteId: $id) {
      id
      status
      body
      make
      model
      family
      subFamily
      color
      category
      productionCountry
      regType
      archived
      vehicle {
        batteryNumber
        electricEngineNumber
        batteryCapacityKwh
        batteryWarranty
      }
      options {
        name
        price
      }
      createdAt {
        date
        timezone_type
        timezone
      }
      updatedAt {
        date
        timezone_type
        timezone
      }
      ... on StandardQuoteDocument {
        currentKm
      }
      ... on StandardBikeQuoteDocument {
        currentKm
        deliveryHour
      }
      ... on ResValQuoteDocument {
        reference
        contractDuration
        pendingUpdate {
          id
          contractDuration
          contractKm
          contractKmStart
          expired
        }
        contractDateStart {
          date
          timezone_type
          timezone
        }
        contractKmStart
        contractKm
        sentInFleetAt {
          date
          timezone_type
          timezone
        }
        pendingUpdate {
          id
          contractDuration
          contractKm
          contractKmStart
          expired
        }
      }
      deliveryKm
      energy
      plate
      doors
      seats
      hpPower
      version
      vin
      tags
      spotRotation
      spotEnquirerRotation
      taxPower
      gearbox
      type
      co2
      displacement
      trimLevel
      kwPower
      euroStandard
      firstSellPrice
      catalogId
      expired
      identificationCountry
      documents {
        id
        type
        uri
        filename
        tags
        mime
      }
      quotedAt {
        date
        timezone_type
        timezone
      }
      quotations {
        id
        quoter {
          id
        }
        quoterGroup {
          id
        }
        publishedAt {
          date
          timezone_type
          timezone
        }
      }
      damages {
        zone
        type
        documents {
          id
          filename
          uri
        }
      }
      releaseDate {
        date
        timezone_type
        timezone
      }
      deliveryDate {
        date
        timezone_type
        timezone
      }
      assignedAt {
        date
        timezone_type
        timezone
      }
      startModelPhase {
        date
        timezone_type
        timezone
      }
      endModelPhase {
        date
        timezone_type
        timezone
      }
      startVersionPhase {
        date
        timezone_type
        timezone
      }
      endVersionPhase {
        date
        timezone_type
        timezone
      }
      firstRegDate {
        date
        timezone_type
        timezone
      }
      lastRegDate {
        date
        timezone_type
        timezone
      }
      enquirer {
        id
        firstname
        lastname
        fullname
      }
      enquirerGroup {
        id
        name
        root {
          id
          name
        }
      }
      prices {
        type
        amount
        createdAt {
          date
          timezone_type
          timezone
        }
        metadata {
          name
          value
        }
        user {
          id
        }
      }
      auctionGroup {
        name
      }
      billingSite {
        id
        name
        number
        zipCode
        city
      }
      withdrawalSite {
        id
        name
        number
        street
        zipCode
        city
      }
      tracking {
        id
        status
        tags
        arrivalDate {
          date
          timezone_type
          timezone
        }
        soldDate {
          date
          timezone_type
          timezone
        }
      }
      contact {
        fullname
      }
    }
  }
`

export const searchQuoteLogQuery: any = gql`
  query ($id: String!, $page: Int, $nbElementByPage: Int) {
    search_quotelog(id: $id, page: $page, nbElementByPage: $nbElementByPage) {
      results {
        id
        action
        loggedAt {
          date
          timezone_type
          timezone
        }
        quote {
          status
        }
        extraData
        user {
          fullname
          currentGroup
        }
        quoterGroup {
          name
        }
        pricing {
          type
        }
      }
      nbResults
    }
  }
`

export const updateQuoteMutation: any = gql`
  mutation ($quoteInput: QuoteInput!) {
    update_quote(quoteInput: $quoteInput) {
      quotations
      quoteSites
      damages
      id
      status
      archived
      category
      ... on StandardQuote {
        currentKm
      }
      ... on ResValQuote {
        reference
        contractDuration
        contractDateStart {
          date
          timezone_type
          timezone
        }
        contractKmStart
        contractKm
      }
      identificationSource
      identificationCountry
      catalogId
      catalogSource
      catalogCountry
      externalId
      externalSource
      enquirer
      enquirerGroup
      auctionGroup
      documents
      nbQuoterPrice
      prices {
        type
        amount
        createdAt {
          date
          timezone_type
          timezone
        }
      }
      plate
      plateSearch
      vin
      make
      family
      subFamily
      model
      version
      trimLevel
      hpPower
      kwPower
      taxPower
      displacement
      gears
      gearbox
      mineType
      co2
      euroStandard
      color
      seats
      doors
      type
      body
      regType
      regBody
      energy
      productionCountry
      tags
      deliveryKm
      firstSellPrice
      expired
      tracking {
        id
        status
        tags
        comment
        soldDate {
          date
          timezone_type
          timezone
        }
        createdAt {
          date
          timezone_type
          timezone
        }
        updatedAt {
          date
          timezone_type
          timezone
        }
      }
      assignedAt {
        date
        timezone_type
        timezone
      }
      releaseDate {
        date
        timezone_type
        timezone
      }
      deliveryDate {
        date
        timezone_type
        timezone
      }
      firstRegDate {
        date
        timezone_type
        timezone
      }
      lastRegDate {
        date
        timezone_type
        timezone
      }
      startModelPhase {
        date
        timezone_type
        timezone
      }
      endModelPhase {
        date
        timezone_type
        timezone
      }
      startVersionPhase {
        date
        timezone_type
        timezone
      }
      endVersionPhase {
        date
        timezone_type
        timezone
      }
    }
  }
`

export const identifyQuery: any = gql`
  query ($plate: String, $vin: String, $country: String) {
    identify(plate: $plate, vin: $vin, country: $country) {
      status
      vin
      plate
      fullyIdentified
      identificationSource
      identificationCountry
      category
      options {
        name
        price
      }
      makes {
        id
        type
        name
        families {
          id
          name
          subFamilies {
            id
            name
            models {
              id
              name
              saleStart
              saleEnd
              versions {
                id
                name
                trimLevel
                saleStart
                saleEnd
                energy
                gearbox
                gears
                body
                doors
                seats
                displacement
                hpPower
                kwPower
                taxPower
                co2
                euroStandard
                price
                curbWeight
                electrical {
                  batteryNumber
                  electricEngineNumber
                  batteryCapacityKwh
                  batteryWarranty
                }
              }
            }
          }
        }
      }
      firstRegDate {
        date
        timezone_type
        timezone
      }
      lastRegDate {
        date
        timezone_type
        timezone
      }
      regType
      regBody
      color
      regColor
      regCo2
      regCurbWeight
      regEnergy
      NTIC
      regLocalTypeCode
    }
  }
`

export const getMakesDataQuery: any = gql`
  query ($filters: MakeFilterInput) {
    get_makes_data(filters: $filters) {
      id
      type
      typeId
      name
    }
  }
`

export const getFamiliesDataQuery: any = gql`
  query ($filters: FamilyFilterInput) {
    get_families_data(filters: $filters) {
      id
      makeId
      name
    }
  }
`

export const getSubFamiliesDataQuery: any = gql`
  query ($filters: SubFamilyFilterInput) {
    get_subfamilies_data(filters: $filters) {
      id
      makeId
      familyId
      name
    }
  }
`

export const getModelsDataQuery: any = gql`
  query ($filters: ModelFilterInput) {
    get_models_data(filters: $filters) {
      id
      type
      typeId
      makeId
      familyId
      subFamilyId
      name
      saleStart
      saleEnd
    }
  }
`

export const getVersionsDataQuery: any = gql`
  query ($filters: VersionFilterInput) {
    get_versions_data(filters: $filters) {
      id
      type
      make {
        id
        type
      }
      family {
        id
        makeId
        name
      }
      subFamily {
        id
        makeId
        familyId
        name
      }
      model {
        id
        type
        typeId
        makeId
        familyId
        subFamilyId
        name
        saleStart
        saleEnd
      }
      name
      trimLevel
      saleStart
      saleEnd
      energy
      gearbox
      gears
      body
      doors
      seats
      displacement
      hpPower
      kwPower
      taxPower
      co2
      euroStandard
      price
      curbWeight
    }
  }
`

export const getBodiesDataQuery: any = gql`
  query ($category: IdentificationCategory) {
    get_bodies_list(category: $category)
  }
`

export const getEnergiesDataQuery: any = gql`
  query ($category: IdentificationCategory) {
    get_energies_list(category: $category)
  }
`

export const getGearboxesDataQuery: any = gql`
  query ($category: IdentificationCategory) {
    get_gearboxes_list(category: $category)
  }
`

export const getEuroStandardsDataQuery: any = gql`
  {
    get_eurostandards_list
  }
`

export const getRegTypesDataQuery: any = gql`
  query ($category: IdentificationCategory) {
    get_regtypes_list(category: $category)
  }
`

export const acceptQuoteQuery: any = gql`
  mutation ($quoteId: String!) {
    accept_quote(id: $quoteId) {
      status
    }
  }
`

export const rejectQuoteQuery: any = gql`
  mutation ($quoteId: String!) {
    reject_quote(id: $quoteId) {
      status
    }
  }
`

export const uploadDocumentMutation: any = gql`
  mutation ($documentInput: UploadDocumentInput!, $file: Upload!) {
    upload_document(documentInput: $documentInput, file: $file) {
      id
      type
      mime
      filename
      uri
      storageType
      weight
      tags
      user
      usergroup
      source
      createdAt {
        date
        timezone_type
        timezone
      }
      updatedAt {
        date
        timezone_type
        timezone
      }
      deletedAt {
        date
        timezone_type
        timezone
      }
    }
  }
`

export const removeDocumentMutation: any = gql`
  mutation ($id: String!) {
    remove_document(id: $id) {
      successful
    }
  }
`

export const addDamageMutation: any = gql`
  mutation ($damageInput: DamageInput!) {
    add_damage(damageInput: $damageInput) {
      id
      zone
      type
      documents
      price
      userGroup
      createdAt {
        date
        timezone_type
        timezone
      }
      updatedAt {
        date
        timezone_type
        timezone
      }
    }
  }
`

export const removeDamageMutation: any = gql`
  mutation ($damageInput: DamageInput!) {
    remove_damage(damageInput: $damageInput) {
      successful
    }
  }
`

export const getCommentsQuery: any = gql`
  query ($filters: CommentSearchFilterInput, $page: Int) {
    search_comment(filters: $filters, page: $page) {
      results {
        id
        content
        author {
          username
          lastname
          fullname
        }
        createdAt {
          date
          timezone_type
          timezone
        }
        updatedAt {
          date
          timezone_type
          timezone
        }
        type
      }
    }
  }
`

export const autoEstimateQuoteMutation: any = gql`
  mutation ($id: String!, $quotationGroupId: String) {
    autoestimate_quote(id: $id, quotationGroupId: $quotationGroupId) {
      id
    }
  }
`

export const correctQuoteMutation: any = gql`
  mutation ($id: String!) {
    correct_quote(id: $id) {
      quotations
      quoteSites
      damages
      id
      status
      archived
      identificationSource
      identificationCountry
      catalogId
      catalogSource
      catalogCountry
      externalId
      externalSource
      enquirer
      enquirerGroup
      auctionGroup
      documents
      nbQuoterPrice
      ... on StandardQuote {
        currentKm
      }
      ... on ResValQuote {
        reference
        contractDuration
        contractDateStart {
          date
          timezone_type
          timezone
        }
        contractKmStart
        contractKm
      }
      prices {
        type
        amount
        createdAt {
          date
          timezone_type
          timezone
        }
      }
      plate
      plateSearch
      vin
      make
      family
      subFamily
      model
      version
      trimLevel
      hpPower
      kwPower
      taxPower
      displacement
      gears
      gearbox
      mineType
      co2
      euroStandard
      color
      seats
      doors
      type
      body
      regType
      regBody
      energy
      productionCountry
      tags
      deliveryKm
      firstSellPrice
      expired
      tracking {
        id
        status
        tags
        comment
        soldDate {
          date
          timezone_type
          timezone
        }
        createdAt {
          date
          timezone_type
          timezone
        }
        updatedAt {
          date
          timezone_type
          timezone
        }
      }
      assignedAt {
        date
        timezone_type
        timezone
      }
      releaseDate {
        date
        timezone_type
        timezone
      }
      deliveryDate {
        date
        timezone_type
        timezone
      }
      firstRegDate {
        date
        timezone_type
        timezone
      }
      lastRegDate {
        date
        timezone_type
        timezone
      }
      startModelPhase {
        date
        timezone_type
        timezone
      }
      endModelPhase {
        date
        timezone_type
        timezone
      }
      startVersionPhase {
        date
        timezone_type
        timezone
      }
      endVersionPhase {
        date
        timezone_type
        timezone
      }
    }
  }
`

export const listSitesQuery: any = gql`
  query ($filters: SiteFilterInput) {
    list_sites(filters: $filters) {
      results {
        id
        name
        number
        street
        city
        zipCode
        type
      }
      nbPages
    }
  }
`

export const assignQuoteQuery: any = gql`
  mutation (
    $id: String!
    $auctionGroupId: String!
    $billingSiteId: String!
    $withdrawalSiteId: String!
  ) {
    assign_quote(
      id: $id
      auctionGroupId: $auctionGroupId
      assignSitesInput: { billingSiteId: $billingSiteId, withdrawalSiteId: $withdrawalSiteId }
    ) {
      id
      plate
    }
  }
`

export const getDefaultAuctionGroupIdQuery: any = gql`
  query getDefaultAuctionGroupIid {
    get_default_auction_group_id
  }
`

export const listExportsQuery: any = gql`
  query ($filters: ExportListFiltersInput, $page: Int, $nbElementByPage: Int) {
    list_exports(filters: $filters, page: $page, nbElementByPage: $nbElementByPage) {
      results {
        id
        type
        format
        status
        document
        createdAt {
          date
        }
      }
      nbResults
    }
  }
`

export const exportQuotesQuery: any = gql`
  mutation (
    $filters: QuoteExportFiltersInput!
    $fieldGroups: [String!]
    $removeEmptyColumns: Boolean
    $locale: String
  ) {
    export_quotes(
      filters: $filters
      fieldGroups: $fieldGroups
      removeEmptyColumns: $removeEmptyColumns
      locale: $locale
    ) {
      id
      user
      userGroup
      type
      status
      document
      format
      createdAt {
        date
        timezone_type
        timezone
      }
      updatedAt {
        date
        timezone_type
        timezone
      }
    }
  }
`

export const listDocumentsQuery: any = gql`
  query ($filters: DocumentFilterInput) {
    list_documents(filters: $filters) {
      results {
        id
        type
        mime
        filename
        uri
        storageType
        weight
        tags
        user
        usergroup
        source
        createdAt {
          date
          timezone_type
          timezone
        }
        updatedAt {
          date
          timezone_type
          timezone
        }
        deletedAt {
          date
          timezone_type
          timezone
        }
      }
    }
  }
`

export const generalFacetsQuery: any = gql`
  query search_quote {
    results {
      id
      status
      plate
      vin
      make
      model
      version
      deliveryKm
      releaseDate {
        date
        timezone_type
        timezone
      }
      deliveryDate {
        date
        timezone_type
        timezone
      }
      identificationCountry
      enquirerGroup {
        name
        root {
          name
        }
      }
      quotations {
        quoterGroup {
          id
        }
        quoter {
          id
          fullname
        }
        publishedAt {
          date
          timezone_type
          timezone
        }
      }
      prices {
        type
        amount
        createdAt {
          date
          timezone_type
          timezone
        }
      }
    }
    nbResults
    currentPage
    nbElementByPage
  }
`

export const MonitoringFacetsQuery: any = gql`
  query search_quote {
    results {
      id
      status
      plate
      vin
      make
      model
      version
      deliveryKm
      releaseDate {
        date
        timezone_type
        timezone
      }
      deliveryDate {
        date
        timezone_type
        timezone
      }
      identificationCountry
      enquirerGroup {
        name
        root {
          name
        }
      }
      quotations {
        quoterGroup {
          id
        }
        quoter {
          id
          fullname
        }
        publishedAt {
          date
          timezone_type
          timezone
        }
      }
      prices {
        type
        amount
        createdAt {
          date
          timezone_type
          timezone
        }
      }
    }
    nbResults
    currentPage
    nbElementByPage
  }
`

export const getMyGroupsQuery: any = gql`
  query {
    get_my_groups {
      id
      name
    }
  }
`

export const switchGroupQuery: any = gql`
  mutation ($groupId: String!) {
    switch_group(groupId: $groupId) {
      id
      currentGroup {
        id
      }
      config
      rights
    }
  }
`

export const validateAssignmentMutation: any = gql`
  mutation ($id: String!) {
    validate_assignment(id: $id) {
      id
    }
  }
`

export const rejectAssignmentMutation: any = gql`
  mutation ($id: String!) {
    reject_assignment(id: $id) {
      id
    }
  }
`

export const getGroupDetailsQuery: any = gql`
  query ($currentGroupId: String) {
    list_groups(filters: { parentId: $currentGroupId }, nbElementByPage: 500) {
      results {
        userGroups
        groupSites
        ownedRoles
        id
        name
        level
        rightsMask
        children {
          id
          name
        }
        role {
          id
          name
          noChildrenAllowed
        }
        inheritedRole {
          id
          name
          noChildrenAllowed
        }
        rootGroup {
          id
          name
        }
      }
      nbPages
      nbResults
      currentPage
      nbElementByPage
    }
  }
`

export const getGroupQueryApollo: any = gql`
  query ($id: String!) {
    get_group(id: $id) {
      children {
        userGroups
        groupSites
        ownedRoles
        id
        name
        level
        rightsMask
        role {
          id
          name
          noChildrenAllowed
        }
        inheritedRole {
          id
          name
          noChildrenAllowed
        }
        rootGroup {
          id
          name
        }
      }
      userGroups
      groupSites
      ownedRoles
      id
      name
      level
      rightsMask
      role {
        id
        name
        noChildrenAllowed
      }
      inheritedRole {
        id
        name
        noChildrenAllowed
      }
      rootGroup {
        id
        name
      }
    }
  }
`

export const adminExpiredQuoteApollo: any = gql`
  mutation ($id: String!, $expired: Boolean!) {
    admin_expired_quote(id: $id, expired: $expired) {
      id
    }
  }
`
export const adminTagsQuoteMutation: any = gql`
  mutation ($id: String!, $tags: [String!]!, $crashedComment: String) {
    admin_tags_quote(id: $id, tags: $tags, crashedComment: $crashedComment) {
      id
      tags
    }
  }
`

export const adminPriceQuoteMutation: any = gql`
  mutation (
    $id: String!
    $amount: Float!
    $comment: String!
    $quoterGroupId: String
    $usePreviousMargin: Boolean
  ) {
    admin_price_quote(
      id: $id
      amount: $amount
      comment: $comment
      quoterGroupId: $quoterGroupId
      usePreviousMargin: $usePreviousMargin
    ) {
      successful
      message
    }
  }
`

export const adminSiteQuoteMutation: any = gql`
  mutation ($id: String!, $siteId: String!, $typeSite: String!) {
    admin_site_quote(id: $id, siteId: $siteId, typeSite: $typeSite) {
      successful
      message
    }
  }
`

export const listSitesQueryApollo: any = gql`
  query ($filters: SiteFilterInput) {
    list_sites(filters: $filters) {
      results {
        id
        name
        number
        street
        city
        zipCode
      }
    }
  }
`

export const listGroupQuery: any = gql`
  query ($nameLike: String) {
    list_groups(filters: { nameLike: $nameLike }) {
      results {
        id
        name
        rootGroup {
          id
          name
        }
      }
    }
  }
`

export const listUsersQuery: any = gql`
  query ($filters: UserFilterInput, $page: Int) {
    list_users(filters: $filters, page: $page, nbElementByPage: 100) {
      results {
        id
        firstname
        lastname
      }
    }
  }
`

export const adminMoveQuoteMutation: any = gql`
  mutation ($id: String!, $enquirerGroupId: String, $enquirerId: String) {
    admin_move_quote(id: $id, enquirerGroupId: $enquirerGroupId, enquirerId: $enquirerId) {
      successful
    }
  }
`

export const getDefaultQuoterGroupIdQuery: any = gql`
  {
    get_default_quoter_group_id
  }
`

export const duplicateQuoteQuery: any = gql`
  mutation (
    $id: String!
    $quoteInput: DuplicateQuoteInput!
    $comment: String
    $options: DuplicateQuoteOptions
  ) {
    duplicate: duplicate_quote(
      id: $id
      quoteInput: $quoteInput
      comment: $comment
      options: $options
    ) {
      id
      category
      status
    }
  }
`
export const sendQuoteQuery: any = gql`
  mutation ($quoteId: String!, $quoterGroupId: String) {
    send_quote(quoteId: $quoteId, quoterGroupId: $quoterGroupId) {
      id
    }
  }
`

export const sendInFleetQuoteQuery: any = gql`
  mutation ($quoteInput: QuoteFleetInput!, $comment: String) {
    send_in_fleet_quote(quoteInput: $quoteInput, comment: $comment) {
      plate
      make
    }
  }
`
export const updateFleetQuoteQuery: any = gql`
  mutation ($id: String!, $pendingUpdate: PendingUpdateInput!, $comment: String) {
    update_fleet_quote(id: $id, pendingUpdateInput: $pendingUpdate, comment: $comment) {
      status
    }
  }
`

export const closeCaseQuoteQuery: any = gql`
  mutation ($quoteInput: QuoteCloseCaseInput!, $comment: String) {
    close_case_quote(quoteInput: $quoteInput, comment: $comment) {
      id
      plate
      make
      model
    }
  }
`

export const keepItemQuoteQuery: any = gql`
  mutation ($id: String!) {
    keep_item_quote(id: $id) {
      id
    }
  }
`
export const getQuoteAutoriginQuery: any = gql`
  query getQuoteAutoriginQuery($quoteId: String!) {
    get_quote_autorigin(quoteId: $quoteId) {
      id
      uri
    }
  }
`

export const getQuoteRadarQuery: any = gql`
  query getQuoteRadarQuery($quoteId: String!) {
    get_quote_radar(quoteId: $quoteId)
  }
`

export const updateAppSettingsUserQuery: any = gql`
  mutation ($settings: JSON!) {
    update_app_settings_user(app: PROVIDER, settings: $settings) {
      provider
    }
  }
`

export const getMultiQuotationQuery: any = gql`
  query ($filters: QuoteSearchFilterInput, $page: Int, $nbElementByPage: Int) {
    search_multiquotation(filters: $filters, page: $page, nbElementByPage: $nbElementByPage) {
      results {
        id
        status
        make
        model
        version
        trimLevel
        tags
        deliveryKm
        contact {
          fullname
        }
        category
        ... on ResValQuoteDocument {
          reference
          contractDuration
          contractDateStart {
            date
            timezone_type
            timezone
          }
          contractKmStart
          contractKm
        }
        releaseDate {
          date
          timezone_type
          timezone
        }
        deliveryDate {
          date
          timezone_type
          timezone
        }
        enquirer {
          fullname
        }
        enquirerGroup {
          name
          root {
            name
          }
        }
        quotations {
          quoter {
            fullname
          }
        }
        prices {
          type
          amount
          user {
            fullname
          }
          createdAt {
            date
            timezone_type
            timezone
          }
        }
      }
      nbResults
      currentPage
      nbElementByPage
    }
  }
`

export const pricingQuoteQuery: any = gql`
  mutation ($id: String!, $amount: Float!, $type: String) {
    pricing_quote(quoteId: $id, amount: $amount, type: $type) {
      id
      amount
    }
  }
`
