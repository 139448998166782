// @flow

import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import HelpIcon from '@material-ui/icons/Help'
import IconButton from '@material-ui/core/IconButton'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { MODES } from 'utils/constants/modes'
import { useTranslation } from 'react-i18next'
import { MIN_DELIVERY_DATE, MIN_DELIVERY_DATE_RESVAL_MODE } from 'quote/cmp/validationRules'
import { isInteger } from 'utils/number'
import FieldLabel from 'quote/cmp/FieldLabel'

import type { Mode } from 'types/modes'

type DefaultProps = {
  classes: Object,
  field: Function,
  onChange: Function,
  identifiedVehicle?: Object,
  formValidator: Function,
}
type DeliveryProps = DefaultProps & { mode: Mode, formValidator: Function }
type StandardProps = DefaultProps & { mode: Mode }

const styles = () => ({
  formFields: {
    maxWidth: 350,
    paddingTop: 20,
  },
  formControl: {
    marginBottom: '2rem',
    '& p': {
      color: '#414b56',
    },
  },
  textInput: {
    border: 'none',
    borderRadius: '0px',
    borderBottom: '1px solid black',
    paddingLeft: 5,
    paddingTop: '1rem',
  },
  textInputLabel: {
    transform: 'translate(0, -0.5rem)',
  },
  questionLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  helpIcon: {
    padding: '0 0 0 5px',
    opacity: '0.8',
    position: 'absolute',
    left: 220,
  },
  tooltip: {
    fontSize: '14px',
  },
  rightLabel: {
    marginLeft: 80,
    color: 'red',
  },
})

const Standard = ({
  classes,
  field,
  onChange,
  formValidator,
  identifiedVehicle,
  mode,
}: StandardProps) => {
  const { t } = useTranslation()

  const deliveryStepErrors = formValidator().steps[4].errors
  const kmMismatchError = deliveryStepErrors?.kmMismatch
  const finalCommentPlaceholder =
    mode === MODES.standard_bike
      ? t('quote.generalFeedbackPlaceholderForBike')
      : t('quote.generalFeedbackPlaceholder')

  const handleChangeInputNumber = e => {
    const { value, name } = e.target

    if (isInteger(value)) {
      onChange({ target: { name, value } })
    }
  }

  return (
    <>
      <TextField
        name="currentKm"
        label={<FieldLabel label={t('quote.currentMileage')} isRequired />}
        placeholder={t('quote.notSpecified')}
        value={field('currentKm')}
        onChange={handleChangeInputNumber}
        className={classes.formControl}
        InputLabelProps={{ shrink: true }}
        onInput={e => {
          e.target.value = e.target.value.slice(0, 6)
        }}
        autoComplete="off"
        fullWidth
      />
      <TextField
        name="deliveryDate"
        label={<FieldLabel label={t('quote.deliveryDate')} isRequired />}
        value={field('deliveryDate')}
        placeholder={t('quote.notSpecified')}
        type="date"
        InputLabelProps={{ shrink: true }}
        inputProps={{ min: MIN_DELIVERY_DATE }}
        onChange={onChange}
        className={classes.formControl}
        autoComplete="off"
        fullWidth
      />
      <TextField
        name="deliveryKm"
        label={<FieldLabel label={t('quote.deliveryMileage')} isRequired />}
        placeholder={t('quote.notSpecified')}
        value={field('deliveryKm')}
        onChange={handleChangeInputNumber}
        className={classes.formControl}
        InputLabelProps={{ shrink: true }}
        onInput={e => {
          e.target.value = e.target.value.slice(0, 6)
        }}
        style={{ minWidth: 250 }}
        autoComplete="off"
        fullWidth
        error={kmMismatchError}
        helperText={kmMismatchError && t('quote.identification.errors.mileageMismatch')}
      />
      {mode === MODES.standard_bike && (
        <TextField
          name="deliveryHour"
          label={t('quote.numberOfEngineHours')}
          placeholder={t('quote.notSpecified')}
          value={field('deliveryHour')}
          onChange={handleChangeInputNumber}
          className={classes.formControl}
          InputLabelProps={{ shrink: true }}
          onInput={e => {
            e.target.value = e.target.value.slice(0, 6)
          }}
          style={{ minWidth: 250 }}
          autoComplete="off"
          fullWidth
        />
      )}
      <TextField
        name="finalComment"
        label={t('quote.generalFeedback')}
        value={field('finalComment')}
        placeholder={identifiedVehicle?.oldFinalComment ?? finalCommentPlaceholder}
        type="text"
        multiline
        rows="4"
        InputLabelProps={{ classes: { root: classes.textInputLabel } }}
        InputProps={{ classes: { root: classes.textInput } }}
        onChange={onChange}
        className={classes.formControl}
        fullWidth
      />
      <Typography
        color="secondary"
        variant="caption"
        style={{ position: 'relative', top: '-1.5rem' }}
      >
        {t('quote.identification.generalFeedbackHelper')}
      </Typography>
    </>
  )
}

const Resval = ({ classes, field, onChange, identifiedVehicle, formValidator }: DefaultProps) => {
  const { t } = useTranslation()

  const deliveryStepErrors = formValidator().steps.find(element => element.step === 3)?.errors
  const kmToStartError = deliveryStepErrors?.kmToStart
  const kmToGoError = deliveryStepErrors?.kmToGo
  const contractDateStartError = deliveryStepErrors?.contractDateStart
  const contractDurationError = deliveryStepErrors?.contractDuration

  const handleChangeInputNumber = e => {
    const { value, name } = e.target

    if (isInteger(value)) {
      onChange({ target: { name, value } })
    }
  }

  return (
    <>
      <TextField
        name="kmToStart"
        label={<FieldLabel label={t('quote.kmToStart')} isRequired />}
        placeholder={`${t('globals.exampleShortColon')} 1000`}
        value={field('kmToStart')}
        onChange={handleChangeInputNumber}
        className={classes.formControl}
        InputLabelProps={{ shrink: true }}
        autoComplete="off"
        error={kmToStartError}
        helperText={kmToStartError && t('quote.identification.errors.kmToStart')}
        fullWidth
      />
      <TextField
        name="kmToGo"
        label={<FieldLabel label={t('quote.kmToGo')} isRequired />}
        placeholder={`${t('globals.exampleShortColon')} 1000`}
        value={field('kmToGo')}
        onChange={handleChangeInputNumber}
        className={classes.formControl}
        InputLabelProps={{ shrink: true }}
        autoComplete="off"
        error={kmToGoError}
        helperText={kmToGoError && t('quote.identification.errors.kmToGo')}
        fullWidth
      />
      <TextField
        name="contractDateStart"
        label={<FieldLabel label={t('quote.contractDateStart')} isRequired />}
        value={field('contractDateStart')}
        placeholder={t('quote.notSpecified')}
        type="date"
        InputLabelProps={{ shrink: true }}
        inputProps={{ min: MIN_DELIVERY_DATE_RESVAL_MODE }}
        onChange={onChange}
        className={classes.formControl}
        autoComplete="off"
        error={contractDateStartError}
        helperText={contractDateStartError && t('quote.identification.errors.contractDateStart')}
        fullWidth
      />
      <TextField
        name="contractDuration"
        label={<FieldLabel label={t('quote.contractDuration')} isRequired />}
        placeholder={`${t('globals.exampleShortColon')} 36`}
        value={field('contractDuration')}
        onChange={handleChangeInputNumber}
        className={classes.formControl}
        InputLabelProps={{ shrink: true }}
        autoComplete="off"
        error={contractDurationError}
        helperText={contractDurationError && t('quote.identification.errors.contractDuration')}
        fullWidth
      />
      <FormControl fullWidth margin="dense" className={classes.formControl}>
        <FormLabel component="legend" className={classes.questionLabel}>
          {t('quote.identification.questions.hasVat')}
          <Tooltip
            title="VU, Dérivé VP, VSL, CTTE, location, VTC, Taxi"
            classes={{ tooltip: classes.tooltip }}
            placement="right"
          >
            <IconButton className={classes.helpIcon}>
              <HelpIcon />
            </IconButton>
          </Tooltip>
        </FormLabel>
        <RadioGroup value={field('vat')} name="vat" row {...{ onChange }}>
          <FormControlLabel
            value="on"
            control={<Radio color="secondary" />}
            label={t('globals.yes')}
          />
          <FormControlLabel
            value="off"
            control={<Radio color="secondary" />}
            label={t('globals.no')}
            classes={{ root: classes.rightLabel }}
          />
        </RadioGroup>
      </FormControl>
      <TextField
        name="finalComment"
        label={t('quote.generalFeedback')}
        value={field('finalComment')}
        placeholder={identifiedVehicle?.oldFinalComment ?? t('quote.generalFeedbackPlaceholder')}
        type="text"
        multiline
        rows="4"
        InputLabelProps={{ classes: { root: classes.textInputLabel } }}
        InputProps={{ classes: { root: classes.textInput } }}
        onChange={onChange}
        className={classes.formControl}
        fullWidth
      />
    </>
  )
}

const Delivery = (props: DeliveryProps) => {
  const { mode, classes } = props

  return (
    <div className={classes.formFields}>
      {mode === MODES.resval ? <Resval {...props} /> : <Standard {...props} mode={mode} />}
    </div>
  )
}

export default (withStyles(styles)(Delivery): any)
