// @flow

import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import moment from 'moment'
import TextField from '@material-ui/core/TextField'
import { useTranslation } from 'react-i18next'
import { MAX_KM, getMinDeliveryDateForCloseCase } from 'quote/cmp/validationRules'
import { getLanguage } from 'utils/language'
import { CURRENT_DATE } from 'utils/constants/date'
import { isInteger } from 'utils/number'
import FieldLabel from 'quote/cmp/FieldLabel'

const language = getLanguage()

const styles = () => ({
  formFields: {
    maxWidth: 350,
    paddingTop: 20,
  },
  formControl: {
    marginBottom: '2rem',
    '& p': {
      color: '#414b56',
    },
  },
  textInput: {
    border: 'none',
    borderRadius: '0px',
    borderBottom: '1px solid black',
    paddingLeft: 5,
    paddingTop: '1rem',
  },
  textInputLabel: {
    transform: 'translate(0, -0.5rem)',
  },
})

type Props = {
  classes: Object,
  field: Function,
  onChange: Function,
  formValidator: Function,
  contractKmStart?: number,
  sentInFleetAt?: string,
}

const CloseQuotation = ({
  classes,
  field,
  onChange,
  formValidator,
  contractKmStart,
  sentInFleetAt,
}: Props) => {
  const { t } = useTranslation()

  const deliveryStepErrors = formValidator().steps.find(element => element.step === 3)?.errors
  const deliveryKmError = deliveryStepErrors?.deliveryKm
  const deliveryDateError = deliveryStepErrors?.deliveryDate

  const sentInFleetAtFormatted = moment(sentInFleetAt).format('YYYY-MM-DD')

  const minDeliveryDate = getMinDeliveryDateForCloseCase(sentInFleetAtFormatted)

  const handleChangeInputNumber = e => {
    const { value, name } = e.target

    if (isInteger(value)) {
      onChange({ target: { name, value } })
    }
  }

  return (
    <div className={classes.formFields}>
      <TextField
        name="deliveryKm"
        label={
          <FieldLabel label={t('quote.identification.closeQuotation.deliveryKm')} isRequired />
        }
        placeholder={`${t('globals.exampleShortColon')} 1000`}
        value={field('deliveryKm')}
        onChange={handleChangeInputNumber}
        className={classes.formControl}
        InputLabelProps={{ shrink: true }}
        autoComplete="off"
        error={deliveryKmError}
        helperText={
          deliveryKmError &&
          t('quote.identification.errors.closeQuotation.deliveryKm', {
            min: contractKmStart,
            max: MAX_KM,
          })
        }
      />
      <TextField
        name="deliveryDate"
        label={
          <FieldLabel label={t('quote.identification.closeQuotation.deliveryDate')} isRequired />
        }
        value={field('deliveryDate')}
        type="date"
        onChange={onChange}
        className={classes.formControl}
        InputLabelProps={{ shrink: true }}
        inputProps={{
          min: minDeliveryDate,
          max: CURRENT_DATE,
        }}
        autoComplete="off"
        error={deliveryDateError}
        helperText={
          deliveryDateError &&
          t('quote.identification.errors.closeQuotation.deliveryDate', {
            min: minDeliveryDate && new Date(minDeliveryDate).toLocaleDateString(language),
            max: new Date().toLocaleDateString(language),
          })
        }
      />
      <TextField
        name="customerLastname"
        label={t('quote.customerLastname')}
        value={field('customerLastname')}
        type="text"
        onChange={onChange}
        className={classes.formControl}
        InputLabelProps={{ shrink: true }}
        placeholder={`${t('globals.exampleShortColon')} Dupont`}
        autoComplete="off"
      />
      <TextField
        name="customerFirstname"
        label={t('quote.customerFirstname')}
        value={field('customerFirstname')}
        type="text"
        onChange={onChange}
        className={classes.formControl}
        InputLabelProps={{ shrink: true }}
        placeholder={`${t('globals.exampleShortColon')} Jean`}
        autoComplete="off"
      />
      <TextField
        name="feedbackCloseQuotation"
        label={t('quote.identification.closeQuotation.feedback')}
        value={field('feedbackCloseQuotation')}
        placeholder={t('quote.generalFeedbackPlaceholder')}
        type="text"
        multiline
        rows="4"
        InputLabelProps={{ classes: { root: classes.textInputLabel } }}
        InputProps={{ classes: { root: classes.textInput } }}
        onChange={onChange}
        className={classes.formControl}
      />
    </div>
  )
}

export default (withStyles(styles)(CloseQuotation): any)
