import { isMobile } from 'react-device-detect'
import forOwn from 'lodash/forOwn'
import { MODES } from 'utils/constants/modes'
import moment from 'moment'
import { CURRENT_DATE } from 'utils/constants/date'

export const MIN_KM = 0
export const MAX_KM = 999999
export const MIN_DELIVERY_DATE = moment('1900-01-01').format('YYYY-MM-DD')
export const MIN_DELIVERY_DATE_RESVAL_MODE = moment().format('YYYY-MM-DD')
export const MIN_CONTRACT_DURATION = 0
export const MAX_CONTRACT_DURATION = 150

export const NO_REQUIRED_PICTURE = ['PNAVD', 'PNAVG', 'PNARD', 'PNARG']
const details = { requiredFields: ['plate', 'vin', 'firstRegDate'] }

export const detailsBike = identificationMode => {
  const detailsBikeIdentificationManual = {
    requiredFields: [
      'vin',
      'make',
      'model',
      'energy',
      'gearbox',
      'displacement',
      'regType',
      'firstRegDate',
      'lastRegDate',
    ],
  }

  const detailsBikeIdentificationAuto = {
    requiredFields: [
      'vin',
      'make',
      'model',
      'energy',
      'gearbox',
      'hpPower',
      'kwPower',
      'taxPower',
      'displacement',
      'bodywork',
      'seats',
      'regType',
      'firstRegDate',
      'lastRegDate',
    ],
  }

  return identificationMode === 'manual'
    ? detailsBikeIdentificationManual
    : detailsBikeIdentificationAuto
}

const photos = {
  requiredFields: [],
  minimumPictures: 4,
  optional: !isMobile,
}

const standardRules = [
  { ...details, stepIndex: 1 },
  {
    stepIndex: 2,
    requiredFields: ['previous_use', 'damaged', 'broken', 'serviceRecord', 'vat', 'foreign'],
  },
  { ...photos, stepIndex: 3 },
  {
    stepIndex: 4,
    requiredFields: ['damagesComment', 'hasBeenTried', 'rating', 'mechanicalCost'],
  },
  { stepIndex: 5, requiredFields: ['currentKm', 'deliveryKm', 'deliveryDate'] },
]

const resvalRules = [
  { ...details, stepIndex: 1 },
  { ...photos, stepIndex: 2, optional: true },
  {
    stepIndex: 3,
    requiredFields: ['kmToStart', 'kmToGo', 'contractDateStart', 'contractDuration', 'vat'],
  },
]

const standardBikeRules = identificationMode => [
  { ...detailsBike(identificationMode), stepIndex: 1 },
  {
    stepIndex: 2,
    requiredFields: [
      'previous_use',
      'damaged',
      'broken',
      'serviceRecord',
      'vat',
      'foreign',
      'hasAbs',
      'hasVehicleInspection',
    ],
  },
  { ...photos, stepIndex: 3 },
  {
    stepIndex: 4,
    requiredFields: ['damagesComment', 'hasBeenTried', 'rating', 'mechanicalCost'],
  },
  { stepIndex: 5, requiredFields: ['currentKm', 'deliveryKm', 'deliveryDate'] },
]

const closeQuoteRules = [
  { ...photos, stepIndex: 1 },
  {
    stepIndex: 2,
    requiredFields: ['damagesComment', 'hasBeenTried', 'rating', 'mechanicalCost'],
  },
  {
    stepIndex: 3,
    requiredFields: ['deliveryKm', 'deliveryDate'],
  },
]

const validationRules = (mode, questionsRequired = [], identificationMode) => {
  if (mode === MODES.resval) return resvalRules

  if (mode === 'close') return closeQuoteRules

  if (mode === MODES.standard_bike) return standardBikeRules(identificationMode)

  return standardRules.map(element => {
    if (element.stepIndex === 2) {
      return {
        stepIndex: element.stepIndex,
        requiredFields: [...element.requiredFields, ...questionsRequired],
      }
    }

    return element
  })
}

export const getMinDeliveryDateForCloseCase = sentInFleetAt =>
  sentInFleetAt === CURRENT_DATE
    ? CURRENT_DATE
    : moment(sentInFleetAt).add(1, 'days').format('YYYY-MM-DD')

const checkDeliveryStepResvalMode = field => {
  const errors = {}
  const kmToStart = field('kmToStart')
  const kmToGo = field('kmToGo')
  const contractDateStart = field('contractDateStart')
  const contractDuration = field('contractDuration')

  const hasErrorkmToStart = kmToStart && (kmToStart < MIN_KM || kmToStart > MAX_KM)
  const hasErrorkmToGo = kmToGo && (kmToGo < MIN_KM || kmToGo > MAX_KM)
  const hasErrorContractDateStart =
    contractDateStart && moment(contractDateStart).isBefore(MIN_DELIVERY_DATE_RESVAL_MODE)
  const hasErrorContractDuration =
    contractDuration !== '' &&
    (contractDuration < MIN_CONTRACT_DURATION || contractDuration > MAX_CONTRACT_DURATION)

  if (hasErrorkmToStart) errors.kmToStart = hasErrorkmToStart
  if (hasErrorkmToGo) errors.kmToGo = hasErrorkmToGo
  if (hasErrorContractDateStart) errors.contractDateStart = hasErrorContractDateStart
  if (hasErrorContractDuration) errors.contractDuration = hasErrorContractDuration

  return errors
}

const checkCloseStep = (identifiedVehicle, field) => {
  const errors = {}
  const deliveryKm = field('deliveryKm')
  const deliveryDate = field('deliveryDate')
  const deliveryDateFormatted = deliveryDate ? moment(deliveryDate).format('YYYY-MM-DD') : null
  const sentInFleetAt = moment(identifiedVehicle?.sentInFleetAt?.date).format('YYYY-MM-DD')
  const minDeliveryDate = getMinDeliveryDateForCloseCase(sentInFleetAt)

  const hasErrorDeliveryKm =
    deliveryKm && (deliveryKm < identifiedVehicle?.contractKmStart || deliveryKm > MAX_KM)
  const hasErrorDeliveryDate =
    deliveryDateFormatted &&
    (deliveryDateFormatted < minDeliveryDate || deliveryDateFormatted > CURRENT_DATE)

  if (hasErrorDeliveryKm) errors.deliveryKm = hasErrorDeliveryKm
  if (hasErrorDeliveryDate) errors.deliveryDate = hasErrorDeliveryDate

  return errors
}

const checkDamagesStep = (damages, isComplete) => {
  const errors = {}

  let allDamagesHavePictures = true
  forOwn(damages, (damage, key) => {
    if (NO_REQUIRED_PICTURE.includes(key)) return

    allDamagesHavePictures = allDamagesHavePictures && damage.pictures.length > 0
  })

  const complete = isComplete && allDamagesHavePictures

  if (!allDamagesHavePictures) {
    errors.missingPictures = !allDamagesHavePictures
  }

  return { isComplete: complete, errors }
}
const checkValidationRulesStandardMode = ({
  field,
  selectedVersion,
  hasRequiredPhotos,
  damages,
  mode,
  questionsRequired,
}) => {
  const validationState = {
    steps: [],
    isValid: true,
    hasError: false,
  }

  validationRules(mode, questionsRequired).forEach(step => {
    let isComplete = true
    let errors = {}
    step.requiredFields.forEach(fieldName => {
      isComplete = isComplete && field(fieldName) !== ''
    })
    if (step.stepIndex === 1) {
      isComplete = isComplete && selectedVersion !== null
    }
    if (step.stepIndex === 2) {
      if (field('damaged') === 'on') isComplete = isComplete && field('crashedComment')
      if (['EL'].includes(selectedVersion && selectedVersion.energy)) {
        isComplete =
          isComplete && field('batteryType') && field('hasWarranty') && field('hasChargingCable')
        if (field('hasChargingCable') === 'on')
          isComplete = isComplete && field('hasPowerCableMode2') && field('hasPowerCableMode3')
      }
      if (
        ['EE', 'EM', 'ER', 'FL', 'GL', 'NE', 'PE'].includes(
          selectedVersion && selectedVersion.energy,
        )
      ) {
        isComplete = isComplete && field('hasChargingCable')
        if (field('hasChargingCable') === 'on')
          isComplete = isComplete && field('hasPowerCableMode2') && field('hasPowerCableMode3')
      }
      if (selectedVersion && selectedVersion.energy !== 'EL') {
        isComplete = isComplete && field('timingBelt')
      }
    }
    if (step.stepIndex === 3) {
      isComplete = hasRequiredPhotos
    }
    if (step.stepIndex === 4 && isMobile) {
      const damagesStep = checkDamagesStep(damages, isComplete)
      // eslint-disable-next-line prefer-destructuring
      isComplete = damagesStep.isComplete
      errors = { ...errors, ...damagesStep.errors }
    }
    if (step.stepIndex === 5) {
      const kmMismatch =
        field('deliveryKm') &&
        field('currentKm') &&
        parseInt(field('deliveryKm'), 10) < parseInt(field('currentKm'), 10)
      if (kmMismatch) {
        errors.kmMismatch = kmMismatch
      }
    }
    validationState.steps.push({
      step: step.stepIndex,
      isComplete,
      errors,
    })
    validationState.hasError = validationState.hasError || Object.keys(errors).length > 0
    if (step.optional !== true) {
      validationState.isValid =
        validationState.isValid && isComplete && Object.keys(errors).length === 0
    }
  })

  return validationState
}

const checkValidationRulesStandardBikeMode = ({
  hasVehicleInspectionDocument,
  field,
  hasRequiredPhotos,
  damages,
  mode,
  questionsRequired,
  identificationMode,
}) => {
  const validationState = {
    steps: [],
    isValid: true,
    hasError: false,
  }

  const energy = field('energy')

  validationRules(mode, questionsRequired, identificationMode).forEach(step => {
    let isComplete = true
    let errors = {}
    step.requiredFields.forEach(fieldName => {
      isComplete = isComplete && field(fieldName) !== ''
    })
    if (step.stepIndex === 2) {
      if (field('damaged') === 'on') isComplete = isComplete && field('crashedComment')
      if (field('hasVehicleInspection') === 'on') isComplete = hasVehicleInspectionDocument
      if (['EL'].includes(energy)) {
        isComplete =
          isComplete &&
          field('batteryType') &&
          field('hasWarranty') &&
          field('hasChargingCable') &&
          field('batteryNumber')
      }
      if (['GH', 'EH'].includes(energy)) {
        isComplete = isComplete && field('batteryNumber')
      }
      if (['EE', 'EM', 'ER', 'FL', 'GL', 'NE', 'PE'].includes(energy)) {
        isComplete = isComplete && field('hasChargingCable')
      }
      if (energy !== 'EL') {
        isComplete = isComplete && field('timingBelt')
      }
    }
    if (step.stepIndex === 3) {
      isComplete = hasRequiredPhotos
    }
    if (step.stepIndex === 4 && isMobile) {
      const damagesStep = checkDamagesStep(damages, isComplete)
      // eslint-disable-next-line prefer-destructuring
      isComplete = damagesStep.isComplete
      errors = { ...errors, ...damagesStep.errors }
    }
    if (step.stepIndex === 5) {
      const kmMismatch =
        field('deliveryKm') &&
        field('currentKm') &&
        parseInt(field('deliveryKm'), 10) < parseInt(field('currentKm'), 10)
      if (kmMismatch) {
        errors.kmMismatch = kmMismatch
      }
    }
    validationState.steps.push({
      step: step.stepIndex,
      isComplete,
      errors,
    })
    validationState.hasError = validationState.hasError || Object.keys(errors).length > 0
    if (step.optional !== true) {
      validationState.isValid =
        validationState.isValid && isComplete && Object.keys(errors).length === 0
    }
  })

  return validationState
}

const checkValidationRulesResvalMode = ({ field, selectedVersion, hasRequiredPhotos, mode }) => {
  const validationState = {
    steps: [],
    isValid: true,
    hasError: false,
  }

  validationRules(mode).forEach(step => {
    let isComplete = true
    let errors = {}

    step.requiredFields.forEach(fieldName => {
      isComplete = isComplete && field(fieldName) !== ''
    })
    if (step.stepIndex === 1) {
      isComplete = isComplete && selectedVersion !== null
    }
    if (step.stepIndex === 2) {
      isComplete = hasRequiredPhotos
    }
    if (step.stepIndex === 3) {
      errors = { ...errors, ...checkDeliveryStepResvalMode(field) }
    }
    validationState.steps.push({
      step: step.stepIndex,
      isComplete,
      errors,
    })
    validationState.hasError = validationState.hasError || Object.keys(errors).length > 0
    if (step.optional !== true) {
      validationState.isValid =
        validationState.isValid && isComplete && Object.keys(errors).length === 0
    }
  })
  return validationState
}

const checkValidationRulesCloseQuote = ({
  field,
  damages,
  hasRequiredPhotos,
  identifiedVehicle,
}) => {
  const validationState = {
    steps: [],
    isValid: true,
    hasError: false,
  }

  validationRules('close').forEach(step => {
    let isComplete = true
    let errors = {}

    step.requiredFields.forEach(fieldName => {
      isComplete = isComplete && field(fieldName) !== ''
    })
    if (step.stepIndex === 1) {
      isComplete = hasRequiredPhotos
    }
    if (step.stepIndex === 2 && isMobile) {
      const damagesStep = checkDamagesStep(damages, isComplete)
      // eslint-disable-next-line prefer-destructuring
      isComplete = damagesStep.isComplete
      errors = { ...errors, ...damagesStep.errors }
    }
    if (step.stepIndex === 3) {
      errors = { ...errors, ...checkCloseStep(identifiedVehicle, field) }
    }

    validationState.steps.push({
      step: step.stepIndex,
      isComplete,
      errors,
    })
    validationState.hasError = validationState.hasError || Object.keys(errors).length > 0
    if (step.optional !== true) {
      validationState.isValid =
        validationState.isValid && isComplete && Object.keys(errors).length === 0
    }
  })

  return validationState
}

export const checkValidationRules = data => {
  if (data.isCloseMode) return checkValidationRulesCloseQuote(data)

  if (data.mode === MODES.resval) return checkValidationRulesResvalMode(data)

  if (data.mode === MODES.standard_bike) {
    return checkValidationRulesStandardBikeMode(data)
  }

  return checkValidationRulesStandardMode(data)
}
