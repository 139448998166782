// @flow

import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import { useTranslation } from 'react-i18next'

const styles = () => ({
  required: {
    fontSize: '0.675rem',
    color: '#888',
    paddingLeft: 5,
  },
})

type Props = {
  classes: Object,
  label: string,
  isRequired?: boolean,
}

const FieldLabel = ({ classes, label, isRequired }: Props) => {
  const { t } = useTranslation()

  return (
    <>
      {label} {isRequired && <span className={classes.required}>({t('globals.required')})</span>}
    </>
  )
}

export default (withStyles(styles)(FieldLabel): any)
