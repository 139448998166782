// @flow

import React, { useRef, useState } from 'react'
import { useMutation } from '@apollo/client'
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import Pictures from '@autodisol/ads-js/components/Pictures'
import compose from 'recompose/compose'
import { connect as withRedux } from 'react-redux'
import { removeDocumentMutation, uploadDocumentMutation } from 'quote/quote-queries'
import { DOCUMENT_TYPE } from 'utils/constants/quote'
import { useTranslation } from 'react-i18next'
import { getModesData } from 'utils/modes'
import { MODES } from 'utils/constants/modes'
import { TAG_REG_CARD } from '_client/config'
import PhotoElement from 'quote/cmp/Photos/PhotoElement'
import AddPictureButton from 'quote/cmp/AddPictureButton'
import { resizeFile } from 'utils/resize'
import { getDocumentTags } from 'quote/functions'
import { TYPES_MIME } from 'utils/constants'
import CircularProgress from '@material-ui/core/CircularProgress'
import { notify } from 'sharyn/redux/actions'

import type { Mode } from 'types/modes'

import RegCard from '../../../../public/img/RegCard.svg'

const MAXIMUM_NUMBER_OF_PICTURES_AT_A_TIME = 15

const handlePictureUpload =
  ({
    currentDraftQuoteId,
    uploadDocument,
    field,
    identifiedVehicle: i,
    setUploadedPictures,
    isEditionMode,
    isCorrectionMode,
    resizeEnabled,
    tags,
    type,
    isCloseMode,
    pictureMaxWidth,
    pictureMaxHeight,
    pictureQuality,
  }: {
    currentDraftQuoteId: any,
    uploadDocument: Function,
    field: Function,
    identifiedVehicle: Object,
    setUploadedPictures: Function,
    isEditionMode: any,
    isCorrectionMode: any,
    resizeEnabled: boolean,
    tags: string[],
    type: string,
    isCloseMode?: boolean,
    pictureMaxWidth: number,
    pictureMaxHeight: number,
    pictureQuality: number,
  }) =>
  async e => {
    e.preventDefault()

    let image = e.target.files && e.target.files[0]

    if (!image) return

    if (resizeEnabled && image.type !== TYPES_MIME.PDF) {
      const imageResize = await resizeFile({
        file: image,
        pictureMaxWidth,
        pictureMaxHeight,
        pictureQuality,
      })
      if (imageResize.size < image.size) image = imageResize
    }

    const resp = await uploadDocument({
      variables: {
        documentInput: {
          type,
          tags,
          vin: i?.vin ?? field('vin'),
          quoteId: isEditionMode || isCorrectionMode || isCloseMode ? i?.id : currentDraftQuoteId,
        },
        file: image,
      },
    })

    if (resp?.data?.upload_document) {
      setUploadedPictures(prevState => [
        ...prevState,
        {
          id: resp.data.upload_document.id,
          name: resp.data.upload_document.filename,
          src: resp.data.upload_document.uri,
          tags: resp.data.upload_document.tags[0] ?? [],
          type: resp.data.upload_document.type,
          mime: resp.data.upload_document.mime,
        },
      ])
    }
  }

const deletePicture =
  ({ uploadedPictures, setUploadedPictures, removeDocument, picture }) =>
  async () => {
    const resp = await removeDocument({
      variables: {
        id: picture?.id,
      },
    })

    if (resp?.data?.remove_document?.successful) {
      setUploadedPictures(uploadedPictures.filter(v => v.id !== picture?.id))
    }
  }

const PhotosCmp = ({
  classes: css,
  currentDraftQuoteId,
  field,
  identifiedVehicle,
  uploadedPictures,
  setUploadedPictures,
  isEditionMode,
  isCorrectionMode,
  resizeEnabled,
  hasRequiredPhotos,
  schema,
  isCloseMode,
  mode,
  pictureMaxWidth,
  pictureMaxHeight,
  pictureQuality,
  dispatch,
}: {
  classes: any,
  currentDraftQuoteId: any,
  field: any,
  identifiedVehicle: any,
  uploadedPictures: any,
  setUploadedPictures: Function,
  isEditionMode: any,
  isCorrectionMode: any,
  resizeEnabled: any,
  hasRequiredPhotos: any,
  schema: Object[],
  isCloseMode?: boolean,
  mode: Mode,
  pictureMaxWidth: number,
  pictureMaxHeight: number,
  pictureQuality: number,
  dispatch: Function,
}) => {
  const { t } = useTranslation()
  const [actualTags, setActualTags] = useState(undefined)

  const pictureInputRef = useRef<HTMLInputElement | null>(null)
  const regCardInputRef = useRef<HTMLInputElement | null>(null)
  const [numberOfPictures, setNumberOfPictures] = useState(0)
  const [numberOfPicturesUploaded, setNumberOfPicturesUploaded] = useState(0)

  const [uploadDocument, { loading: isPictureUploading }] = useMutation(uploadDocumentMutation)
  const [removeDocument] = useMutation(removeDocumentMutation)

  const isBikeMode = mode === MODES.standard_bike
  const hasFoundRegCardPicture = uploadedPictures.find(e => e.type === TAG_REG_CARD) !== undefined

  const handleChangeMultipleFile =
    ({
      identifiedVehicle: i,
      tags,
      type,
    }: {
      identifiedVehicle: Object,
      tags: string[],
      type: string,
    }) =>
    async e => {
      const { files } = e.target
      const filesArray: File[] = Array.from(files)

      if (!files) return

      if (filesArray.length > MAXIMUM_NUMBER_OF_PICTURES_AT_A_TIME) {
        dispatch(
          notify({
            title: t('quote.addPictures.notification.title'),
            message: t('quote.addPictures.notification.message'),
          }),
        )

        return
      }

      setNumberOfPicturesUploaded(0)
      setNumberOfPictures(filesArray.length)

      // eslint-disable-next-line no-restricted-syntax
      for (const file of filesArray) {
        let image = file

        if (resizeEnabled && image.type !== TYPES_MIME.PDF) {
          // eslint-disable-next-line no-await-in-loop
          const imageResize = await resizeFile({
            file: image,
            pictureMaxWidth,
            pictureMaxHeight,
            pictureQuality,
          })

          if (imageResize.size < image.size) image = imageResize
        }

        // eslint-disable-next-line no-await-in-loop
        const response = await uploadDocument({
          variables: {
            documentInput: {
              type,
              tags,
              vin: i?.vin ?? field('vin'),
              quoteId:
                isEditionMode || isCorrectionMode || isCloseMode ? i?.id : currentDraftQuoteId,
            },
            file: image,
          },
        })

        if (response?.data?.upload_document) {
          setUploadedPictures(prevState => [
            ...prevState,
            {
              id: response.data.upload_document.id,
              name: response.data.upload_document.filename,
              src: response.data.upload_document.uri,
              tags: response.data.upload_document.tags[0] ?? [],
              type: response.data.upload_document.type,
              mime: response.data.upload_document.mime,
            },
          ])

          setNumberOfPicturesUploaded(prevState => prevState + 1)
        }
      }
    }

  const handlePhotoButton = (value?: string) => () => {
    if (value === TAG_REG_CARD) {
      regCardInputRef.current && regCardInputRef.current.click()
      setActualTags(undefined)
      return
    }

    setActualTags(value)
    pictureInputRef.current && pictureInputRef.current.click()
  }

  const handleClickAddPickture = () => {
    setActualTags(undefined)
  }

  const handleChangeInputFile = type => e => {
    handlePictureUpload({
      currentDraftQuoteId,
      uploadDocument,
      field,
      identifiedVehicle,
      setUploadedPictures,
      isEditionMode,
      isCorrectionMode,
      resizeEnabled,
      tags: getDocumentTags(actualTags, type),
      type,
      isCloseMode,
      pictureMaxWidth,
      pictureMaxHeight,
      pictureQuality,
    })(e)
  }

  const handleDelete = value => () => {
    const picture = uploadedPictures.find(e => e.tags === value || e.type === value)

    if (!picture) return

    deletePicture({
      uploadedPictures,
      setUploadedPictures,
      removeDocument,
      picture,
    })()

    if (picture?.type === DOCUMENT_TYPE.REG_CARD && regCardInputRef.current) {
      regCardInputRef.current.value = ''
      return
    }

    if (pictureInputRef.current) {
      pictureInputRef.current.value = ''
    }
  }

  const circularProgressValue = numberOfPictures
    ? (numberOfPicturesUploaded / numberOfPictures) * 100
    : 0

  return (
    <>
      <Grid spacing={40} container justify="center" style={{ maxWidth: 600, textAlign: 'center' }}>
        <div className={css.introduction}>
          <h2>
            {t(
              `quote.identification.picture.${
                isBikeMode ? 'bike' : 'car'
              }.reachMinimimPicturesNumber`,
            )}
          </h2>
          <p>{t('quote.identification.picture.helpText')}</p>
        </div>

        <input
          ref={pictureInputRef}
          name="picture"
          type="file"
          accept="image/png, image/jpg, image/jpeg, fake/mime" // fake/mime is a workaround for bug with latest Android version
          onChange={handleChangeInputFile(DOCUMENT_TYPE.PHOTO)}
          style={{ display: 'none' }}
        />
        <input
          ref={regCardInputRef}
          name="regCard"
          type="file"
          accept=".pdf, image/jpg, image/jpeg, fake/mime" // fake/mime is a workaround for bug with latest Android version
          onChange={handleChangeInputFile(DOCUMENT_TYPE.REG_CARD)}
          style={{ display: 'none' }}
        />

        {schema.map(zone => {
          const pictureFound = uploadedPictures.find(e => e.tags === zone?.tags)

          return (
            <PhotoElement
              key={zone.id}
              title={t(`quote.identification.schema.${zone.id}`)}
              subtitle={zone.subtitle ? t(`quote.identification.schema.${zone.id}Subtitle`) : ''}
              svg={zone.svg}
              handlePhotoButton={handlePhotoButton(zone.tags)}
              uploadedPictures={uploadedPictures}
              isPictureUploading={
                schema.find(e => e.tags === actualTags)?.tags === zone?.tags &&
                !hasRequiredPhotos &&
                isPictureUploading
              }
              handleDelete={handleDelete(zone.tags)}
              pictures={uploadedPictures.filter(e => e.tags === zone?.tags) ?? []}
              isDisabled={pictureFound?.tags === zone?.tags || isPictureUploading}
              hasPicture={pictureFound !== undefined}
            />
          )
        })}
        {isBikeMode && (
          <PhotoElement
            title={t(`quote.identification.schema.bike.regCard`)}
            svg={<RegCard />}
            handlePhotoButton={handlePhotoButton(TAG_REG_CARD)}
            uploadedPictures={uploadedPictures}
            isPictureUploading={!hasRequiredPhotos && !actualTags && isPictureUploading}
            handleDelete={handleDelete(TAG_REG_CARD)}
            pictures={uploadedPictures.filter(e => e.type === TAG_REG_CARD) ?? []}
            isDisabled={hasFoundRegCardPicture || isPictureUploading}
            hasPicture={hasFoundRegCardPicture}
          />
        )}
      </Grid>

      <AddPictureButton
        onClick={handleClickAddPickture}
        handleChangeInputFile={handleChangeMultipleFile({
          identifiedVehicle,
          tags: getDocumentTags(actualTags, DOCUMENT_TYPE.PHOTO),
          type: DOCUMENT_TYPE.PHOTO,
        })}
        disabled={!hasRequiredPhotos || isPictureUploading}
        isLoading={hasRequiredPhotos && isPictureUploading}
        accept="image/png, image/jpg, image/jpeg, fake/mime" // fake/mime is a workaround for bug with latest Android version
        content={t('quote.addPictures.title')}
        height="tall"
        isMultiple
        style={{ marginTop: 30 }}
      >
        {numberOfPictures > 0 && (
          <div style={{ position: 'absolute', right: 20, height: 40 }}>
            <CircularProgress
              variant="static"
              color="primary"
              value={Math.floor(circularProgressValue)}
              size={40}
            />
            <span
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 'max-content',
                fontSize: '0.65rem',
                fontWeight: 900,
              }}
            >
              {numberOfPicturesUploaded} / {numberOfPictures}
            </span>
          </div>
        )}
      </AddPictureButton>

      <Grid container spacing={16} justify="center" style={{ marginTop: 30 }}>
        {uploadedPictures
          .filter(e => e.tags === 'commercial')
          .map(picture => (
            <Grid key={picture.id} item md={2} xs={6}>
              <Pictures
                pictures={[picture]}
                deletable
                maxImagesInARow={1}
                justify="flex-start"
                handleDelete={deletePicture({
                  uploadedPictures,
                  setUploadedPictures,
                  removeDocument,
                  picture: uploadedPictures.find(
                    uploadedPicture => uploadedPicture.id === picture.id,
                  ),
                })}
              />
            </Grid>
          ))}
      </Grid>
    </>
  )
}

const PhotosStyle = withStyles(() => ({
  introduction: {
    padding: '0 10%',
    '& > h2': {
      fontSize: '1rem',
      marginBottom: 5,
    },
    '& > p': {
      fontSize: '0.75rem',
      margin: 0,
    },
  },
}))(PhotosCmp)

const Photos: any = compose(
  withRedux(({ router, user }) => {
    const { currentMode } = getModesData(user, router.location.pathname)

    return { isCloseMode: router?.location?.state?.mode === 'close', mode: currentMode }
  }),
)(PhotosStyle)

export default Photos
